exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-alumni-donate-tsx": () => import("./../../../src/pages/alumni/donate.tsx" /* webpackChunkName: "component---src-pages-alumni-donate-tsx" */),
  "component---src-pages-alumni-index-tsx": () => import("./../../../src/pages/alumni/index.tsx" /* webpackChunkName: "component---src-pages-alumni-index-tsx" */),
  "component---src-pages-alumni-live-tsx": () => import("./../../../src/pages/alumni/live.tsx" /* webpackChunkName: "component---src-pages-alumni-live-tsx" */),
  "component---src-pages-bristol-donate-tsx": () => import("./../../../src/pages/bristol/donate.tsx" /* webpackChunkName: "component---src-pages-bristol-donate-tsx" */),
  "component---src-pages-bristol-index-tsx": () => import("./../../../src/pages/bristol/index.tsx" /* webpackChunkName: "component---src-pages-bristol-index-tsx" */),
  "component---src-pages-bristol-live-tsx": () => import("./../../../src/pages/bristol/live.tsx" /* webpackChunkName: "component---src-pages-bristol-live-tsx" */),
  "component---src-pages-cambridge-404-tsx": () => import("./../../../src/pages/cambridge/404.tsx" /* webpackChunkName: "component---src-pages-cambridge-404-tsx" */),
  "component---src-pages-cambridge-donate-tsx": () => import("./../../../src/pages/cambridge/donate.tsx" /* webpackChunkName: "component---src-pages-cambridge-donate-tsx" */),
  "component---src-pages-cambridge-index-tsx": () => import("./../../../src/pages/cambridge/index.tsx" /* webpackChunkName: "component---src-pages-cambridge-index-tsx" */),
  "component---src-pages-cambridge-live-tsx": () => import("./../../../src/pages/cambridge/live.tsx" /* webpackChunkName: "component---src-pages-cambridge-live-tsx" */),
  "component---src-pages-cambridge-partner-tsx": () => import("./../../../src/pages/cambridge/partner.tsx" /* webpackChunkName: "component---src-pages-cambridge-partner-tsx" */),
  "component---src-pages-cambridge-privacy-tsx": () => import("./../../../src/pages/cambridge/privacy.tsx" /* webpackChunkName: "component---src-pages-cambridge-privacy-tsx" */),
  "component---src-pages-cambridge-yearbook-tsx": () => import("./../../../src/pages/cambridge/yearbook.tsx" /* webpackChunkName: "component---src-pages-cambridge-yearbook-tsx" */),
  "component---src-pages-chapters-tsx": () => import("./../../../src/pages/chapters.tsx" /* webpackChunkName: "component---src-pages-chapters-tsx" */),
  "component---src-pages-demo-donate-tsx": () => import("./../../../src/pages/demo/donate.tsx" /* webpackChunkName: "component---src-pages-demo-donate-tsx" */),
  "component---src-pages-demo-index-tsx": () => import("./../../../src/pages/demo/index.tsx" /* webpackChunkName: "component---src-pages-demo-index-tsx" */),
  "component---src-pages-demo-live-tsx": () => import("./../../../src/pages/demo/live.tsx" /* webpackChunkName: "component---src-pages-demo-live-tsx" */),
  "component---src-pages-durham-donate-tsx": () => import("./../../../src/pages/durham/donate.tsx" /* webpackChunkName: "component---src-pages-durham-donate-tsx" */),
  "component---src-pages-durham-index-tsx": () => import("./../../../src/pages/durham/index.tsx" /* webpackChunkName: "component---src-pages-durham-index-tsx" */),
  "component---src-pages-durham-live-tsx": () => import("./../../../src/pages/durham/live.tsx" /* webpackChunkName: "component---src-pages-durham-live-tsx" */),
  "component---src-pages-edinburgh-donate-tsx": () => import("./../../../src/pages/edinburgh/donate.tsx" /* webpackChunkName: "component---src-pages-edinburgh-donate-tsx" */),
  "component---src-pages-edinburgh-index-tsx": () => import("./../../../src/pages/edinburgh/index.tsx" /* webpackChunkName: "component---src-pages-edinburgh-index-tsx" */),
  "component---src-pages-edinburgh-live-tsx": () => import("./../../../src/pages/edinburgh/live.tsx" /* webpackChunkName: "component---src-pages-edinburgh-live-tsx" */),
  "component---src-pages-glasgow-donate-tsx": () => import("./../../../src/pages/glasgow/donate.tsx" /* webpackChunkName: "component---src-pages-glasgow-donate-tsx" */),
  "component---src-pages-glasgow-index-tsx": () => import("./../../../src/pages/glasgow/index.tsx" /* webpackChunkName: "component---src-pages-glasgow-index-tsx" */),
  "component---src-pages-glasgow-live-tsx": () => import("./../../../src/pages/glasgow/live.tsx" /* webpackChunkName: "component---src-pages-glasgow-live-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leeds-donate-tsx": () => import("./../../../src/pages/leeds/donate.tsx" /* webpackChunkName: "component---src-pages-leeds-donate-tsx" */),
  "component---src-pages-leeds-index-tsx": () => import("./../../../src/pages/leeds/index.tsx" /* webpackChunkName: "component---src-pages-leeds-index-tsx" */),
  "component---src-pages-leeds-live-tsx": () => import("./../../../src/pages/leeds/live.tsx" /* webpackChunkName: "component---src-pages-leeds-live-tsx" */),
  "component---src-pages-lse-donate-tsx": () => import("./../../../src/pages/lse/donate.tsx" /* webpackChunkName: "component---src-pages-lse-donate-tsx" */),
  "component---src-pages-lse-index-tsx": () => import("./../../../src/pages/lse/index.tsx" /* webpackChunkName: "component---src-pages-lse-index-tsx" */),
  "component---src-pages-lse-live-tsx": () => import("./../../../src/pages/lse/live.tsx" /* webpackChunkName: "component---src-pages-lse-live-tsx" */),
  "component---src-pages-manchester-donate-tsx": () => import("./../../../src/pages/manchester/donate.tsx" /* webpackChunkName: "component---src-pages-manchester-donate-tsx" */),
  "component---src-pages-manchester-index-tsx": () => import("./../../../src/pages/manchester/index.tsx" /* webpackChunkName: "component---src-pages-manchester-index-tsx" */),
  "component---src-pages-manchester-live-tsx": () => import("./../../../src/pages/manchester/live.tsx" /* webpackChunkName: "component---src-pages-manchester-live-tsx" */),
  "component---src-pages-oxford-donate-tsx": () => import("./../../../src/pages/oxford/donate.tsx" /* webpackChunkName: "component---src-pages-oxford-donate-tsx" */),
  "component---src-pages-oxford-index-tsx": () => import("./../../../src/pages/oxford/index.tsx" /* webpackChunkName: "component---src-pages-oxford-index-tsx" */),
  "component---src-pages-oxford-live-tsx": () => import("./../../../src/pages/oxford/live.tsx" /* webpackChunkName: "component---src-pages-oxford-live-tsx" */),
  "component---src-pages-oxford-partner-tsx": () => import("./../../../src/pages/oxford/partner.tsx" /* webpackChunkName: "component---src-pages-oxford-partner-tsx" */),
  "component---src-pages-pledge-tsx": () => import("./../../../src/pages/pledge.tsx" /* webpackChunkName: "component---src-pages-pledge-tsx" */),
  "component---src-pages-policies-complaints-tsx": () => import("./../../../src/pages/policies/complaints.tsx" /* webpackChunkName: "component---src-pages-policies-complaints-tsx" */),
  "component---src-pages-policies-index-tsx": () => import("./../../../src/pages/policies/index.tsx" /* webpackChunkName: "component---src-pages-policies-index-tsx" */),
  "component---src-pages-policies-privacy-tsx": () => import("./../../../src/pages/policies/privacy.tsx" /* webpackChunkName: "component---src-pages-policies-privacy-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-ucl-donate-tsx": () => import("./../../../src/pages/ucl/donate.tsx" /* webpackChunkName: "component---src-pages-ucl-donate-tsx" */),
  "component---src-pages-ucl-index-tsx": () => import("./../../../src/pages/ucl/index.tsx" /* webpackChunkName: "component---src-pages-ucl-index-tsx" */),
  "component---src-pages-ucl-live-tsx": () => import("./../../../src/pages/ucl/live.tsx" /* webpackChunkName: "component---src-pages-ucl-live-tsx" */),
  "component---src-pages-warwick-donate-tsx": () => import("./../../../src/pages/warwick/donate.tsx" /* webpackChunkName: "component---src-pages-warwick-donate-tsx" */),
  "component---src-pages-warwick-index-tsx": () => import("./../../../src/pages/warwick/index.tsx" /* webpackChunkName: "component---src-pages-warwick-index-tsx" */),
  "component---src-pages-warwick-live-tsx": () => import("./../../../src/pages/warwick/live.tsx" /* webpackChunkName: "component---src-pages-warwick-live-tsx" */),
  "component---src-pages-workplace-donate-tsx": () => import("./../../../src/pages/workplace/donate.tsx" /* webpackChunkName: "component---src-pages-workplace-donate-tsx" */),
  "component---src-pages-workplace-index-tsx": () => import("./../../../src/pages/workplace/index.tsx" /* webpackChunkName: "component---src-pages-workplace-index-tsx" */),
  "component---src-pages-workplace-live-tsx": () => import("./../../../src/pages/workplace/live.tsx" /* webpackChunkName: "component---src-pages-workplace-live-tsx" */),
  "component---src-pages-yearbook-tsx": () => import("./../../../src/pages/yearbook.tsx" /* webpackChunkName: "component---src-pages-yearbook-tsx" */)
}

